import React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import Container from '../components/container';

class ContactPage extends React.Component {
  render() {
    const formStyle = {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      width: 'min(calc(var(--size-max-width) / 3), calc(100vw - 2 * var(--size-gutter)))',
    }
    return (
      <Layout location={this.props.location}>
        <Seo title='Kontakt' />
        <Container>
          <h1>Kontakt</h1>
          <p>Har du en fråga eller vill felanmäla något? Hör av dig!</p>
          <p>Du kan även maila styrelsen <a href='mailto:info@askholmen.nu'>info@askholmen.nu</a></p>
          <form 
            action='/thankyou'
            name='contact' 
            method='POST'
            data-netlify='true' 
            data-netlify-honeypot='bot-field' 
            style={formStyle}
          >
              <input type='hidden' name='form-name' value='contact'/>
              <input type='text' placeholder='Ditt namn' name='name'/>
              <input type='email' placeholder='Din epost' name='email'/>
              <textarea name='message' placeholder='Ditt meddelande'></textarea>
              <button type='submit'>Skicka</button>
          </form>
        </Container>
      </Layout>
    );
  }
}

export default ContactPage;
